"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ServerMessageType = void 0;
var ServerMessageType;
(function (ServerMessageType) {
    ServerMessageType[ServerMessageType["JOIN_ROOM_RESPONSE"] = 0] = "JOIN_ROOM_RESPONSE";
    ServerMessageType[ServerMessageType["PLAYER_CREATED"] = 1] = "PLAYER_CREATED";
    ServerMessageType[ServerMessageType["PLAYER_DISCONNECTED"] = 2] = "PLAYER_DISCONNECTED";
    ServerMessageType[ServerMessageType["BLOCK_CREATED"] = 3] = "BLOCK_CREATED";
    ServerMessageType[ServerMessageType["BLOCK_MOVED"] = 4] = "BLOCK_MOVED";
    ServerMessageType[ServerMessageType["BLOCK_PLACED"] = 5] = "BLOCK_PLACED";
    ServerMessageType[ServerMessageType["BLOCK_DIED"] = 6] = "BLOCK_DIED";
    ServerMessageType[ServerMessageType["BLOCK_DROPPED"] = 7] = "BLOCK_DROPPED";
    ServerMessageType[ServerMessageType["NEXT_SPAWN"] = 8] = "NEXT_SPAWN";
    ServerMessageType[ServerMessageType["PLAYER_CHANGE_STATUS"] = 9] = "PLAYER_CHANGE_STATUS";
    ServerMessageType[ServerMessageType["NEXT_ROUND"] = 10] = "NEXT_ROUND";
    ServerMessageType[ServerMessageType["CLEAR_LINES"] = 11] = "CLEAR_LINES";
    ServerMessageType[ServerMessageType["END_ROUND"] = 12] = "END_ROUND";
    ServerMessageType[ServerMessageType["START_NEXT_ROUND_TIMER"] = 13] = "START_NEXT_ROUND_TIMER";
    ServerMessageType[ServerMessageType["PLAYER_KILLED"] = 14] = "PLAYER_KILLED";
    ServerMessageType[ServerMessageType["GAME_MODE_EVENT"] = 15] = "GAME_MODE_EVENT";
    ServerMessageType[ServerMessageType["PLAYER_TOGGLE_CHAT"] = 16] = "PLAYER_TOGGLE_CHAT";
    ServerMessageType[ServerMessageType["SIMULATION_MESSAGE"] = 17] = "SIMULATION_MESSAGE";
})(ServerMessageType = exports.ServerMessageType || (exports.ServerMessageType = {}));
