"use strict";
// from https://stackoverflow.com/a/47593316/4562693
Object.defineProperty(exports, "__esModule", { value: true });
exports.simpleRandom = void 0;
function mulberry32(a) {
    var t = (a += 0x6d2b79f5);
    t = Math.imul(t ^ (t >>> 15), t | 1);
    t ^= t + Math.imul(t ^ (t >>> 7), t | 61);
    return ((t ^ (t >>> 14)) >>> 0) / 4294967296;
}
function simpleRandom(seed) {
    return mulberry32(seed);
}
exports.simpleRandom = simpleRandom;
