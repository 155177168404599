"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.teams = exports.yellowTeam = exports.greenTeam = exports.blueTeam = exports.redTeam = void 0;
exports.redTeam = {
    color: 0xf33821,
    name: 'Red',
    patternNumber: 13,
    characterId: '727',
};
exports.blueTeam = {
    color: 0x223ea4,
    name: 'Blue',
    patternNumber: 24,
    characterId: '454',
};
exports.greenTeam = {
    color: 0x049564,
    name: 'Green',
    patternNumber: 3,
    characterId: '205',
};
exports.yellowTeam = {
    color: 0xf7e74d,
    name: 'Yellow',
    patternNumber: 19,
    characterId: '720',
};
exports.teams = [exports.redTeam, exports.blueTeam, exports.greenTeam, exports.yellowTeam];
