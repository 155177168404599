"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const I = [
    [0, 0, 0],
    [1, 1, 1],
    [0, 0, 0],
];
const L = [
    [1, 0],
    [1, 1],
];
const trominoes = {
    id: 'trominoes',
    name: 'Trominoes',
    layouts: { I, L },
};
exports.default = trominoes;
