"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const exampleChallengeDefaultFields_1 = require("./exampleChallengeDefaultFields");
const tSpins1 = Object.assign(Object.assign({}, exampleChallengeDefaultFields_1.exampleChallengeDefaultFields), { isOfficial: true, title: 'T Spins 1', description: '', locale: 'en', isMandatory: true, isPublished: true, priority: 8000, simulationSettings: {
        allowedBlockLayoutIds: ['T'],
    }, finishCriteria: {}, rewardCriteria: {
        gold: {
            maxBlocksPlaced: 10,
        },
        silver: {
            maxBlocksPlaced: 20,
        },
    }, grid: `
0000000000000000000000000000000000000000000000
0000000000000000000000000000000000000000000000
0000000000000000000000000000000000000000000000
0000000000000000000000000000000000000000000000
0000000000000000000000000000000000000000000000
0000000000000000000000000000000000000000000000
0000000000000XX0000XX0000000000000000000000000
0000000000000X0000XX00000000000000000000000000
000000XX0X00XXBXXXX00XXXX000000000000000000000
XXXXRRRXXXGrGXgBXXXX000XXXXXXXXXXXXXXXXXXXXXXX
XXXXXRXXXXXGXXBXXXX000XXXXXXXXXXXXXXXXXXXXXXXX
XXXXXXXXXXXXXXXXXXXX00XXXXXXXXXXXXXXXXXXXXXXXX
XXXXXXXXXXXXXXXXXXX000XXXXXXXXXXXXXXXXXXXXXXXX
XXXXXXXXXXXXXXXXXXXX000XXXXXXXXXXXXXXXXXXXXXXX
XXXXXXXXXXXXXXXXXXXX00bXXXXXXXXXXXXXXXXXXXXXXX
XXXXXXXXXXXXXXXXXXXXXXFXXXXXXXXXXXXXXXXXXXXXXX

`, created: true });
exports.default = tSpins1;
