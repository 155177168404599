"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const exampleChallengeDefaultFields_1 = require("./exampleChallengeDefaultFields");
const keysAndLocks1 = Object.assign(Object.assign({}, exampleChallengeDefaultFields_1.exampleChallengeDefaultFields), { isOfficial: true, title: 'Keys and Locks 1', description: '', locale: 'en', isMandatory: true, isPublished: true, priority: 7000, finishCriteria: {}, rewardCriteria: {
        gold: {
            maxBlocksPlaced: 10,
        },
        silver: {
            maxBlocksPlaced: 20,
        },
    }, grid: `
000000000000000
000000000000000
000000000000000
000000000000000
000000000000000
0ggg00000000000
0gBg00000000000
0ggg00000000000
000000000000000
000000000000000
000000000000000
000000000000000
00000000000000R
00000000000000X
rrrrrrrrrrrrrrr
000000000000000
000000000000000
000000G00000000
bbbbbbbbbbbbbbb
FFFFFFFFFFFFFFF
`, created: true });
exports.default = keysAndLocks1;
