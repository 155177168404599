"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const I = [
    [0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0],
    [1, 1, 1, 1, 1],
    [0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0],
];
const F = [
    [0, 1, 1],
    [1, 1, 0],
    [0, 1, 0],
];
const FF = [
    [1, 1, 0],
    [0, 1, 1],
    [0, 1, 0],
];
const L = [
    [0, 0, 0, 0],
    [0, 0, 0, 1],
    [1, 1, 1, 1],
    [0, 0, 0, 0],
];
const J = [
    [0, 0, 0, 0],
    [1, 0, 0, 0],
    [1, 1, 1, 1],
    [0, 0, 0, 0],
];
const P = [
    [1, 1, 0],
    [1, 1, 1],
    [0, 0, 0],
];
const PP = [
    [0, 1, 1],
    [1, 1, 1],
    [0, 0, 0],
];
const W = [
    [0, 0, 0, 0],
    [1, 1, 1, 0],
    [0, 0, 1, 1],
    [0, 0, 0, 0],
];
const WW = [
    [0, 0, 0, 0],
    [0, 1, 1, 1],
    [1, 1, 0, 0],
    [0, 0, 0, 0],
];
const T = [
    [1, 1, 1],
    [0, 1, 0],
    [0, 1, 0],
];
const U = [
    [1, 0, 1],
    [1, 1, 1],
    [0, 0, 0],
];
const DV = [
    [0, 0, 1],
    [0, 0, 1],
    [1, 1, 1],
];
const DW = [
    [0, 0, 1],
    [0, 1, 1],
    [1, 1, 0],
];
const C = [
    [0, 1, 0],
    [1, 1, 1],
    [0, 1, 0],
];
const G = [
    [0, 0, 0, 0],
    [0, 0, 1, 0],
    [1, 1, 1, 1],
    [0, 0, 0, 0],
];
const GG = [
    [0, 0, 0, 0],
    [0, 1, 0, 0],
    [1, 1, 1, 1],
    [0, 0, 0, 0],
];
const S = [
    [0, 1, 1],
    [0, 1, 0],
    [1, 1, 0],
];
const Z = [
    [1, 1, 0],
    [0, 1, 0],
    [0, 1, 1],
];
const pentominoes = {
    id: 'pentominoes',
    name: 'Pentominoes',
    layouts: { I, F, FF, L, J, P, PP, W, WW, T, U, DV, DW, C, G, GG, S, Z },
};
exports.default = pentominoes;
