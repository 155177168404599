import { Box, MobileStepper, SvgIcon, SxProps } from '@mui/material';
import lodashMerge from 'lodash.merge';
import React from 'react';
import SwipeableViews from 'react-swipeable-views';
import useWindowSize from 'react-use/lib/useWindowSize';
import { ReactComponent as LeftIcon } from '@/icons/left.svg';
import { ReactComponent as RightIcon } from '@/icons/right.svg';
import FlexBox from './FlexBox';
import { colors, zIndexes } from '@/theme/theme';
import useKeyPress from 'react-use/lib/useKeyPress';
import usePrevious from 'react-use/lib/usePrevious';

const defaultMobileStepperStyles = {};

type SwipeableViewsStyles = {
  root: React.CSSProperties;
  slideContainer: React.CSSProperties;
  slide: React.CSSProperties;
};

const coreSwipeableViewsStyles: SwipeableViewsStyles = {
  root: {},
  slideContainer: {
    padding: '15px 5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  slide: {
    padding: '0 15px',
    color: '#fff',
    flexGrow: 1,
  },
};

export const narrowSwipeableViewsStyles: SwipeableViewsStyles = lodashMerge(
  {},
  coreSwipeableViewsStyles,
  {
    root: {
      padding: '0px 40px',
      maxWidth: '400px',
    },
  }
);
export const fullscreenSwipeableViewsStyles: SwipeableViewsStyles = lodashMerge(
  {},
  coreSwipeableViewsStyles,
  {
    root: {},
    slideContainer: {
      padding: '0px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    slide: {
      padding: '0px',
      color: '#fff',
      flexGrow: 1,
    },
  }
);

export const fullscreenMobileStepperStyles: React.CSSProperties = {
  position: 'absolute',
  bottom: '7%',
  width: '100vw',
};

type CarouselProps = {
  slides: React.ReactNode[];
  styles?: SwipeableViewsStyles;
  blurEdges?: boolean;
  mobileStepperStyles?: React.CSSProperties;
  scaleTransform?: boolean;
  innerArrows?: boolean;
  initialStep?: number;
  showArrows?: boolean;
  onChange?(step: number): void;
};

export function Carousel({
  mobileStepperStyles = defaultMobileStepperStyles,
  slides: pages,
  styles = coreSwipeableViewsStyles,
  blurEdges,
  scaleTransform = true,
  showArrows = true,
  innerArrows,
  initialStep = 0,
  onChange,
}: React.PropsWithChildren<CarouselProps>) {
  const [activeStep, _setActiveStep] = React.useState(initialStep);
  const windowSize = useWindowSize();
  const isLandscape = windowSize.width > windowSize.height;
  const arrowDistance = innerArrows ? -100 : 100;
  const [leftPressed] = useKeyPress('ArrowLeft');
  const [rightPressed] = useKeyPress('ArrowRight');
  const prevLeftPressed = usePrevious(leftPressed);
  const prevRightPressed = usePrevious(rightPressed);

  const setActiveStep = React.useCallback(
    (stepIndex) => {
      _setActiveStep(stepIndex);
      onChange?.(stepIndex);
    },
    [onChange]
  );

  React.useEffect(() => {
    if (leftPressed && !prevLeftPressed) {
      setActiveStep(Math.max(activeStep - 1, 0));
    }
  }, [activeStep, leftPressed, prevLeftPressed, setActiveStep]);

  React.useEffect(() => {
    if (rightPressed && !prevRightPressed) {
      setActiveStep(Math.min(activeStep + 1, pages.length - 1));
    }
  }, [activeStep, pages.length, prevRightPressed, rightPressed, setActiveStep]);

  React.useEffect(() => {
    if (pages.length <= activeStep) {
      setActiveStep(Math.max(pages.length - 1, 0));
    }
  }, [activeStep, pages.length, setActiveStep]);

  return (
    <div style={{ position: 'relative' }}>
      {blurEdges && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 1,
            // boxShadow: (theme) =>
            //   `inset 12px 0 15px 0px ${theme.palette.background.paper}, inset -12px 0 15px 0px ${theme.palette.background.paper}`,
            pointerEvents: 'none',
          }}
        />
      )}
      {isLandscape && showArrows && pages.length > 1 && (
        <CarouselArrow
          icon={<LeftIcon />}
          sx={{
            left: -arrowDistance,
            transform: 'translate(-50%,-50%)',
          }}
          onClick={() => setActiveStep(activeStep - 1)}
          enabled={activeStep > 0}
        />
      )}
      {isLandscape && showArrows && pages.length > 1 && (
        <CarouselArrow
          icon={<RightIcon />}
          sx={{
            right: -arrowDistance,
            transform: 'translate(50%,-50%)',
          }}
          onClick={() => setActiveStep(activeStep + 1)}
          enabled={activeStep < pages.length - 1}
        />
      )}
      <SwipeableViews
        index={activeStep}
        onChangeIndex={setActiveStep}
        enableMouseEvents
        style={styles.root}
        slideStyle={styles.slideContainer}
        className={scaleTransform ? 'carousel-scaletransform' : ''}
      >
        {pages}
      </SwipeableViews>
      {pages.length > 1 && (
        <MobileStepper
          style={mobileStepperStyles}
          steps={pages.length}
          position="static"
          variant="dots"
          activeStep={activeStep}
          nextButton={null}
          backButton={null}
        />
      )}
    </div>
  );
}

type CarouselArrowProps = {
  icon: React.ReactNode;
  sx: SxProps;
  onClick(): void;
  enabled: boolean;
};

function CarouselArrow({ icon, sx, onClick, enabled }: CarouselArrowProps) {
  return (
    <FlexBox
      sx={{
        position: 'absolute',
        top: '50%',
        padding: 1,
        zIndex: zIndexes.above,
        filter: 'drop-shadow( 0px 0px 5px rgba(0, 0, 0, .9))',
        cursor: 'pointer',
        opacity: enabled ? 0.9 : 0.3,
        ...sx,
      }}
      onClick={() => enabled && onClick()}
    >
      <SvgIcon
        sx={{
          fontSize: '60px',
          color: colors.white,
        }}
      >
        {icon}
      </SvgIcon>
    </FlexBox>
  );
}
