"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function getUserFriendlyKeyText(key) {
    switch (key) {
        case 'ArrowLeft':
            return '←';
        case 'ArrowRight':
            return '→';
        case 'ArrowUp':
            return '↑';
        case 'ArrowDown':
            return '↓';
        case ' ':
            return 'SPACE';
        default:
            return key;
    }
}
exports.default = getUserFriendlyKeyText;
