const prefix = 'infinitris2-';
const localStorageKeys = {
  localUser: `${prefix}localUser`,
  loggedIn: `${prefix}loggedIn`,
  challenge: `${prefix}challenge`,
  challengeId: `${prefix}challengeId`,
  lastCompletedTestGrid: `${prefix}lastCompletedTestGrid`,
  referredByAffiliateId: `${prefix}affiliateLinkRef`,
  cache: `${prefix}cache`,
};
export default localStorageKeys;
