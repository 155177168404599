"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.colors = void 0;
exports.colors = [
    { hex: '#AEADAD', tier: 0 },
    { hex: '#8be8ff', tier: 0 },
    { hex: '#9bf9d1', tier: 0 },
    { hex: '#fff069', tier: 0 },
    { hex: '#ff7d6b', tier: 0 },
    { hex: '#ff84c6', tier: 0 },
    { hex: '#14246F', tier: 3 },
    { hex: '#223EA4', tier: 3 },
    { hex: '#52C1FF', tier: 2 },
    { hex: '#15444D', tier: 3 },
    { hex: '#1F645B', tier: 3 },
    { hex: '#2BC0BA', tier: 3 },
    { hex: '#9BF9D1', tier: 2 },
    { hex: '#176E39', tier: 3 },
    { hex: '#049564', tier: 3 },
    { hex: '#6CFFA4', tier: 3 },
    { hex: '#FFD912', tier: 3 },
    { hex: '#F7E74D', tier: 3 },
    { hex: '#D8E26A', tier: 2 },
    { hex: '#F33821', tier: 3 },
    { hex: '#FF653F', tier: 3 },
    { hex: '#FB943D', tier: 3 },
    { hex: '#FFA51E', tier: 3 },
    { hex: '#C71F33', tier: 3 },
    { hex: '#F34143', tier: 3 },
    { hex: '#B21A4B', tier: 3 },
    { hex: '#391C78', tier: 3 },
    { hex: '#660266', tier: 3 },
    { hex: '#C03680', tier: 3 },
    { hex: '#DE9BE2', tier: 2 },
    { hex: '#EFEFED', tier: 5 },
    { hex: '#1B1C1C', tier: 5 },
];
