"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClientMessageType = void 0;
var ClientMessageType;
(function (ClientMessageType) {
    ClientMessageType[ClientMessageType["JOIN_ROOM_REQUEST"] = 0] = "JOIN_ROOM_REQUEST";
    ClientMessageType[ClientMessageType["BLOCK_MOVED"] = 1] = "BLOCK_MOVED";
    ClientMessageType[ClientMessageType["BLOCK_DROPPED"] = 2] = "BLOCK_DROPPED";
    ClientMessageType[ClientMessageType["CHAT"] = 3] = "CHAT";
    ClientMessageType[ClientMessageType["TOGGLE_CHAT"] = 4] = "TOGGLE_CHAT";
})(ClientMessageType = exports.ClientMessageType || (exports.ClientMessageType = {}));
