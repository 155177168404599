"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const exampleChallengeDefaultFields_1 = require("./exampleChallengeDefaultFields");
const infectionTest = Object.assign(Object.assign({}, exampleChallengeDefaultFields_1.exampleChallengeDefaultFields), { isOfficial: true, title: 'Infection 1', description: '', locale: 'en', isMandatory: true, isPublished: true, priority: 7000, finishCriteria: {}, rewardCriteria: {
        gold: {
            maxBlocksPlaced: 10,
        },
        silver: {
            maxBlocksPlaced: 20,
        },
    }, grid: `
000000000000000
000000000000000
000000000000000
000000000000000
000000000000000
000000000000000
000000000000000
000000000000000
000R00000000000
000000000000000
000000000000000
000000000000000
000000000000000
000000000000000
000000000000000
000000000000000
000I00000000000
00XXX0000000000
rrrrrrrrrrrrrrr
FFFFFFFFFFFFFFF
`, created: true });
exports.default = infectionTest;
