"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const exampleChallengeDefaultFields_1 = require("./exampleChallengeDefaultFields");
const raceTest = Object.assign(Object.assign({}, exampleChallengeDefaultFields_1.exampleChallengeDefaultFields), { isOfficial: true, title: 'Race 1', description: '', locale: 'en', isMandatory: true, isPublished: true, priority: 7000, simulationSettings: {
        gameModeType: 'race',
        botSettings: {
            numBots: 5,
        },
    }, grid: {
        numRows: 16,
        numColumns: 60,
    }, 
    // finishCriteria: {},
    // rewardCriteria: {
    // },
    created: true });
exports.default = raceTest;
