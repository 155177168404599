"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const exampleChallengeDefaultFields_1 = require("./exampleChallengeDefaultFields");
const wrapTest = Object.assign(Object.assign({}, exampleChallengeDefaultFields_1.exampleChallengeDefaultFields), { isOfficial: true, title: 'Wrap Test', locale: 'en', description: '', isPublished: false, finishCriteria: {}, rewardCriteria: {}, grid: `
X00000000000000000000000000000000000000000000000X
0000000000000000000000000000000000000000000000000
0000000000000000000000000000000000000000000000000
0000000000000000000000000000000000000000000000000
0000000000000000000000000000000000000000000000000
0000000000000000000000000000000000000000000000000
0000000000000000000000000000000000000000000000000
0000000000000000000000000000000000000000000000000
0000000000000000000000000000000000000000000000000
0000000000000000000000000000000000000000000000000
0000000000000000000000000000000000000000000000000
0000000000000000000000000000000000000000000000000
0000000000000000000000000000000000000000000000000
0000000000000000000000000000000000000000000000000
0000000000000000000000000000000000000000000000000
0000000000000000000000000000000000000000000000000
0000000000000000000000000000000000000000000000000
0000000000000000000000000000000000000000000000000
0000000000000000000000000000000000000000000000000
0000000000000000000000000000000000000000000000000
X00000000000000000000000000000000000000000000000X
`, created: true });
exports.default = wrapTest;
