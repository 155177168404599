"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GameModeTypeValues = void 0;
exports.GameModeTypeValues = [
    'infinity',
    'race',
    'conquest',
    'garbage-defense',
    'escape',
    'column-conquest',
    'battle',
];
