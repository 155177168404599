"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEFAULT_KEYBOARD_CONTROLS = void 0;
const InputAction_1 = require("./InputAction");
exports.DEFAULT_KEYBOARD_CONTROLS = {
    [InputAction_1.CustomizableInputAction.MoveLeft]: 'ArrowLeft',
    [InputAction_1.CustomizableInputAction.MoveRight]: 'ArrowRight',
    [InputAction_1.CustomizableInputAction.Drop]: ' ',
    [InputAction_1.CustomizableInputAction.MoveDown]: 'ArrowDown',
    [InputAction_1.CustomizableInputAction.RotateAnticlockwise]: 'z',
    [InputAction_1.CustomizableInputAction.RotateClockwise]: 'x',
    [InputAction_1.CustomizableInputAction.Chat]: 'Enter',
    [InputAction_1.CustomizableInputAction.Esc]: 'Escape',
};
/*start: 'Enter',
  pause: 'p',*/
