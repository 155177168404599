"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const exampleChallengeDefaultFields_1 = require("./exampleChallengeDefaultFields");
const shadowTest = Object.assign(Object.assign({}, exampleChallengeDefaultFields_1.exampleChallengeDefaultFields), { isOfficial: true, title: 'Shadow Test', locale: 'en', description: '', isPublished: false, finishCriteria: {}, rewardCriteria: {}, grid: `
X000000X
00000000
00000000
00000000
00000000
00000000
00000000
X000000X
`, created: true });
exports.default = shadowTest;
