"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const exampleChallengeDefaultFields_1 = require("./exampleChallengeDefaultFields");
const garbageCollection1 = Object.assign(Object.assign({}, exampleChallengeDefaultFields_1.exampleChallengeDefaultFields), { isOfficial: true, title: 'Garbage Collection 1', description: '', locale: 'en', isMandatory: true, isPublished: true, priority: 8000, finishCriteria: {}, rewardCriteria: {
        gold: {
            maxBlocksPlaced: 10,
        },
        silver: {
            maxBlocksPlaced: 20,
        },
    }, grid: `
00000
00000
00000
00000
00000
00000
00000
00000
00000
00000
00000
X0XXX
X000X
X0XXX
XXX00
0X0XX
FFFFF
`, created: true });
exports.default = garbageCollection1;
