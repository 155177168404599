"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Z = exports.T = exports.S = exports.O = exports.L = exports.J = exports.I = void 0;
exports.I = [
    [0, 0, 0, 0],
    [1, 1, 1, 1],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
];
exports.J = [
    [1, 0, 0],
    [1, 1, 1],
    [0, 0, 0],
];
exports.L = [
    [0, 0, 1],
    [1, 1, 1],
    [0, 0, 0],
];
exports.O = [
    [1, 1],
    [1, 1],
];
exports.S = [
    [0, 1, 1],
    [1, 1, 0],
    [0, 0, 0],
];
exports.T = [
    [0, 1, 0],
    [1, 1, 1],
    [0, 0, 0],
];
exports.Z = [
    [1, 1, 0],
    [0, 1, 1],
    [0, 0, 0],
];
const tetrominoes = {
    id: 'tetrominoes',
    name: 'Tetrominoes',
    layouts: { I: exports.I, J: exports.J, L: exports.L, O: exports.O, S: exports.S, T: exports.T, Z: exports.Z },
};
exports.default = tetrominoes;
