"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.objectToDotNotation = void 0;
// TODO: improve typing to force obj to match allowedKeys rather than having to check at runtime
function objectToDotNotation(obj, allowedKeys) {
    const result = objectToDotNotationInternal(obj, allowedKeys);
    const resultKeys = Object.keys(result);
    if (resultKeys.sort().join(',') !== allowedKeys.sort().join(',')) {
        throw new Error(`Unexpected result: ${JSON.stringify(resultKeys)} != ${JSON.stringify(allowedKeys)}`);
    }
    return result;
}
exports.objectToDotNotation = objectToDotNotation;
// inspired by https://stackoverflow.com/a/54907553/4562693
function objectToDotNotationInternal(obj, allowedKeys, parent = [], keyValue = {}) {
    for (const key in obj) {
        const pathParts = [...parent, key];
        const fullPath = pathParts.join('.');
        if (allowedKeys.indexOf(fullPath) >= 0) {
            keyValue[fullPath] = obj[key];
            continue;
        }
        else if (obj[key] !== null && typeof obj[key] === 'object') {
            Object.assign(keyValue, objectToDotNotationInternal(obj[key], allowedKeys, pathParts, keyValue));
        }
    }
    return keyValue;
}
