"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getVariationHueRotation = exports.rotateColor = void 0;
const hexToRgb_1 = require("./hexToRgb");
const rgbToHex_1 = require("./rgbToHex");
// from https://stackoverflow.com/a/37657940/4562693
/* rotateColor : Converts hex value to HSL, shifts
 * hue by a number of degrees and then converts hex, giving complimentary color
 * as a hex value
 * @param  [String] hex : hex value
 * @return [String] : complimentary color as hex value
 */
function rotateColor(hex, shift) {
    let { r, g, b } = (0, hexToRgb_1.hexToRgb)(hex);
    // Convert RGB to HSL
    // Adapted from answer by 0x000f http://stackoverflow.com/a/34946092/4939630
    r /= 255.0;
    g /= 255.0;
    b /= 255.0;
    var max = Math.max(r, g, b);
    var min = Math.min(r, g, b);
    let h, s, l = (max + min) / 2.0;
    if (max == min) {
        h = s = 0; //achromatic
    }
    else {
        var d = max - min;
        s = l > 0.5 ? d / (2.0 - max - min) : d / (max + min);
        if (max == r && g >= b) {
            h = (1.0472 * (g - b)) / d;
        }
        else if (max == r && g < b) {
            h = (1.0472 * (g - b)) / d + 6.2832;
        }
        else if (max == g) {
            h = (1.0472 * (b - r)) / d + 2.0944;
        }
        else if (max == b) {
            h = (1.0472 * (r - g)) / d + 4.1888;
        }
        else {
            throw new Error('h is undefined');
        }
    }
    h = (h / 6.2832) * 360.0 + 0;
    // Shift hue to opposite side of wheel and convert to [0-1] value
    h += shift;
    if (h < 0) {
        h += 360;
    }
    if (h > 360) {
        h -= 360;
    }
    h /= 360;
    // Convert h s and l values into r g and b values
    // Adapted from answer by Mohsen http://stackoverflow.com/a/9493060/4939630
    if (s === 0) {
        r = g = b = l; // achromatic
    }
    else {
        var hue2rgb = function hue2rgb(p, q, t) {
            if (t < 0)
                t += 1;
            if (t > 1)
                t -= 1;
            if (t < 1 / 6)
                return p + (q - p) * 6 * t;
            if (t < 1 / 2)
                return q;
            if (t < 2 / 3)
                return p + (q - p) * (2 / 3 - t) * 6;
            return p;
        };
        var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
        var p = 2 * l - q;
        r = hue2rgb(p, q, h + 1 / 3);
        g = hue2rgb(p, q, h);
        b = hue2rgb(p, q, h - 1 / 3);
    }
    r = Math.round(r * 255);
    g = Math.round(g * 255);
    b = Math.round(b * 255);
    return (0, rgbToHex_1.rgbToHex)(r, g, b);
}
exports.rotateColor = rotateColor;
function getVariationHueRotation(worldVariation) {
    const index = worldVariation;
    const hueVariation = index === 0 ? 0 : Math.ceil(index / 2) * (index % 2 === 1 ? 1 : -1);
    return hueVariation * (index < 3 ? 22.5 : index < 4 ? 45 + 22.5 : 90);
}
exports.getVariationHueRotation = getVariationHueRotation;
