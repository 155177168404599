"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isChallengeCellTypeEnabled = exports.getChallengeCellTypeDescription = void 0;
var ChallengeCellType;
(function (ChallengeCellType) {
    ChallengeCellType["Empty"] = "0";
    ChallengeCellType["Full"] = "X";
    ChallengeCellType["Laser"] = "L";
    ChallengeCellType["Deadly"] = "D";
    ChallengeCellType["RedKey"] = "R";
    ChallengeCellType["RedLock"] = "r";
    ChallengeCellType["ReverseRedLock"] = "d";
    ChallengeCellType["RedSwitch"] = "h";
    ChallengeCellType["GreenKey"] = "G";
    ChallengeCellType["GreenLock"] = "g";
    ChallengeCellType["ReverseGreenLock"] = "n";
    ChallengeCellType["GreenSwitch"] = "H";
    ChallengeCellType["BlueKey"] = "B";
    ChallengeCellType["BlueLock"] = "b";
    ChallengeCellType["ReverseBlueLock"] = "e";
    ChallengeCellType["BlueSwitch"] = "c";
    ChallengeCellType["YellowKey"] = "Y";
    ChallengeCellType["YellowLock"] = "y";
    ChallengeCellType["ReverseYellowLock"] = "w";
    ChallengeCellType["YellowSwitch"] = "t";
    ChallengeCellType["Finish"] = "F";
    ChallengeCellType["Wafer"] = "W";
    ChallengeCellType["Infection"] = "I";
    ChallengeCellType["RockGenerator"] = "S";
    ChallengeCellType["SpawnLocation"] = "s";
    ChallengeCellType["GestureMoveLeft"] = "1";
    ChallengeCellType["GestureMoveRight"] = "2";
    ChallengeCellType["GestureMoveDown"] = "3";
    ChallengeCellType["GestureRotateClockwise"] = "4";
    ChallengeCellType["GestureRotateAnticlockwise"] = "5";
    ChallengeCellType["GestureDrop"] = "6";
    ChallengeCellType["GestureRotateDownClockwise"] = "7";
    ChallengeCellType["GestureRotateDownAnticlockwise"] = "8";
    ChallengeCellType["Checkpoint"] = "C";
    ChallengeCellType["BridgeCreator"] = "x";
    ChallengeCellType["PartialClear"] = "p";
})(ChallengeCellType || (ChallengeCellType = {}));
const duplicateChallengeCellTypeValue = Object.values(ChallengeCellType).find((v, i, a) => a.indexOf(v) !== i);
if (duplicateChallengeCellTypeValue) {
    throw new Error('Duplicate challenge cell type value: ' + duplicateChallengeCellTypeValue);
}
exports.default = ChallengeCellType;
function getChallengeCellTypeDescription(type) {
    switch (type) {
        case ChallengeCellType.Empty:
            return 'Empty';
        case ChallengeCellType.Full:
            return 'Full';
        case ChallengeCellType.Laser:
            return 'Laser';
        case ChallengeCellType.Deadly:
            return 'Deadly';
        case ChallengeCellType.RedKey:
            return 'Red Key';
        case ChallengeCellType.RedLock:
            return 'Red Lock';
        case ChallengeCellType.ReverseRedLock:
            return 'Reverse Red Lock';
        case ChallengeCellType.RedSwitch:
            return 'Red Switch';
        case ChallengeCellType.GreenKey:
            return 'Green Key';
        case ChallengeCellType.GreenLock:
            return 'Green Lock';
        case ChallengeCellType.ReverseGreenLock:
            return 'Reverse Green Lock';
        case ChallengeCellType.GreenSwitch:
            return 'Green Switch';
        case ChallengeCellType.BlueKey:
            return 'Blue Key';
        case ChallengeCellType.BlueLock:
            return 'Blue Lock';
        case ChallengeCellType.ReverseBlueLock:
            return 'Reverse Blue Lock';
        case ChallengeCellType.BlueSwitch:
            return 'Blue Switch';
        case ChallengeCellType.YellowKey:
            return 'Yellow Key';
        case ChallengeCellType.YellowLock:
            return 'Yellow Lock';
        case ChallengeCellType.ReverseYellowLock:
            return 'Reverse Yellow Lock';
        case ChallengeCellType.YellowSwitch:
            return 'Yellow Switch';
        case ChallengeCellType.Finish:
            return 'Finish';
        case ChallengeCellType.Wafer:
            return 'Wafer';
        case ChallengeCellType.Infection:
            return 'Infection';
        case ChallengeCellType.RockGenerator:
            return 'Rock Generator';
        case ChallengeCellType.SpawnLocation:
            return 'Spawn Location';
        case ChallengeCellType.GestureMoveLeft:
            return 'Gesture MoveLeft';
        case ChallengeCellType.GestureMoveRight:
            return 'Gesture MoveRight';
        case ChallengeCellType.GestureMoveDown:
            return 'Gesture MoveDown';
        case ChallengeCellType.GestureRotateClockwise:
            return 'Gesture RotateClockwise';
        case ChallengeCellType.GestureRotateAnticlockwise:
            return 'Gesture RotateAnticlockwise';
        case ChallengeCellType.GestureRotateDownClockwise:
            return 'Gesture RotateDownClockwise';
        case ChallengeCellType.GestureRotateDownAnticlockwise:
            return 'Gesture RotateDownAnticlockwise';
        case ChallengeCellType.GestureDrop:
            return 'Gesture Drop';
        case ChallengeCellType.Checkpoint:
            return 'Checkpoint';
        case ChallengeCellType.BridgeCreator:
            return 'Bridge Creator';
        case ChallengeCellType.PartialClear:
            return 'Partial Clear';
    }
}
exports.getChallengeCellTypeDescription = getChallengeCellTypeDescription;
function isChallengeCellTypeEnabled(type) {
    if (type === ChallengeCellType.Laser) {
        return false;
    }
    return true;
}
exports.isChallengeCellTypeEnabled = isChallengeCellTypeEnabled;
