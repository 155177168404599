var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { query, collection, getDocs, getFirestore, onSnapshot, } from 'firebase/firestore';
import React from 'react';
import useSWR, { useSWRConfig } from 'swr';
import { log } from '../utils/log';
function useCollectionInternal(path, options) {
    const { cache, compare, mutate } = useSWRConfig();
    const collectionQuery = getCollectionQuery(path, options);
    const key = getCollectionQueryKey(collectionQuery);
    const fetcher = React.useCallback(() => __awaiter(this, void 0, void 0, function* () {
        if (!collectionQuery) {
            throw new Error('query is null');
        }
        log('fetch collection start', key);
        let result = yield (yield getDocs(collectionQuery)).docs;
        const cachedValue = cache.get(key);
        const isSame = compare(result.map((doc) => doc.data()), cachedValue === null || cachedValue === void 0 ? void 0 : cachedValue.map((doc) => doc.data()));
        log('fetch collection end', key, isSame);
        if (isSame) {
            // return cached object to not trigger a re-render
            result = cachedValue;
        }
        else {
            // only update the individual documents that actually changed
            updateDocuments(result, cache, compare, mutate);
        }
        return result;
    }), [cache, collectionQuery, compare, mutate, key]);
    return useSWR(key, fetcher);
}
export function useCollection(path, options) {
    const { cache, compare, mutate } = useSWRConfig();
    const swr = useCollectionInternal(path, options);
    const swrMutate = swr.mutate;
    React.useEffect(() => {
        let unsub = () => { };
        if (path && (options === null || options === void 0 ? void 0 : options.listen)) {
            const collectionQuery = getCollectionQuery(path, options);
            if (collectionQuery) {
                unsub = onSnapshot(collectionQuery, (querySnapshot) => {
                    // only update if the collection data actually changed
                    const key = getCollectionQueryKey(collectionQuery);
                    const cachedValue = cache.get(key);
                    const isSame = compare(querySnapshot.docs.map((doc) => doc.data()), cachedValue === null || cachedValue === void 0 ? void 0 : cachedValue.map((doc) => doc.data()));
                    log('collection onSnapshot', path, isSame);
                    if (!isSame) {
                        swrMutate(querySnapshot.docs, false);
                        // only update the individual documents that actually changed
                        updateDocuments(querySnapshot.docs, cache, compare, mutate);
                    }
                });
            }
        }
        return unsub;
    }, [cache, compare, mutate, swrMutate, options, path]);
    return swr;
}
function getCollectionQuery(path, options) {
    var _a;
    let collectionQuery = null;
    if (path) {
        collectionQuery = path
            ? query(collection(getFirestore(), path), ...((_a = options === null || options === void 0 ? void 0 : options.constraints) !== null && _a !== void 0 ? _a : []))
            : null;
    }
    return collectionQuery;
}
function getCollectionQueryKey(collectionQuery) {
    let key = null;
    if (collectionQuery) {
        key = collectionQuery
            ? JSON.stringify(collectionQuery._query)
            : null;
        if (!key) {
            throw new Error('_query property missing - check firestore implementation');
        }
    }
    return key;
}
function updateDocuments(result, cache, compare, mutate) {
    result.forEach((doc) => {
        const cachedValue = cache.get(doc.ref.path);
        const isSame = compare(doc.data(), cachedValue === null || cachedValue === void 0 ? void 0 : cachedValue.data());
        log('fetch collection - update single document', doc.ref.path, isSame);
        if (!isSame) {
            mutate(doc.ref.path, doc, false);
        }
    });
}
