"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getUniqueUserEntityId = exports.getEntityPath = exports.getLoginCodePath = exports.loginCodesPath = exports.getPaymentPath = exports.paymentsPath = exports.donationsPath = exports.getPurchasePath = exports.purchasesPath = exports.getConversionPath = exports.getNetworkImpactPath = exports.getImpactedUserNetworkImpactsPath = exports.networkImpactsPath = exports.impactedUsersPath = exports.getIssueReportPath = exports.issueReportsPath = exports.getRatingPath = exports.ratingsPath = exports.getChallengeAttemptPath = exports.challengeAttemptsPath = exports.getSettingPath = exports.settingsPath = exports.getChallengePath = exports.challengesPath = exports.getServerKeyPath = exports.serverKeysPath = exports.getServerPath = exports.serversPath = exports.getServerRoomPath = exports.getRoomPath = exports.getRoomId = exports.roomsPath = exports.getScoreboardEntryPath = exports.scoreboardEntriesPath = exports.getAffiliatePath = exports.affiliatesPath = exports.getUserPath = exports.usersPath = exports.getCharacterPath = exports.charactersPath = exports.getNicknamePath = exports.nicknamesPath = void 0;
exports.nicknamesPath = 'nicknames';
const getNicknamePath = (nicknameId) => getEntityPath(exports.nicknamesPath, nicknameId);
exports.getNicknamePath = getNicknamePath;
exports.charactersPath = 'characters';
const getCharacterPath = (characterId) => getEntityPath(exports.charactersPath, characterId);
exports.getCharacterPath = getCharacterPath;
exports.usersPath = 'users';
const getUserPath = (userId) => getEntityPath(exports.usersPath, userId);
exports.getUserPath = getUserPath;
exports.affiliatesPath = 'affiliates';
const getAffiliatePath = (affiliateId) => getEntityPath(exports.affiliatesPath, affiliateId);
exports.getAffiliatePath = getAffiliatePath;
exports.scoreboardEntriesPath = 'scoreboardEntries';
const getScoreboardEntryPath = (userId) => getEntityPath(exports.scoreboardEntriesPath, userId);
exports.getScoreboardEntryPath = getScoreboardEntryPath;
exports.roomsPath = 'rooms';
const getRoomId = (serverId, roomIndex) => `${serverId}-${roomIndex}`;
exports.getRoomId = getRoomId;
const getRoomPath = (roomId) => getEntityPath(exports.roomsPath, roomId);
exports.getRoomPath = getRoomPath;
const getServerRoomPath = (serverId, roomIndex) => (0, exports.getRoomPath)((0, exports.getRoomId)(serverId, roomIndex));
exports.getServerRoomPath = getServerRoomPath;
exports.serversPath = 'servers';
const getServerPath = (serverId) => getEntityPath(exports.serversPath, serverId);
exports.getServerPath = getServerPath;
exports.serverKeysPath = 'serverKeys';
const getServerKeyPath = (serverKey) => getEntityPath(exports.serverKeysPath, serverKey);
exports.getServerKeyPath = getServerKeyPath;
exports.challengesPath = 'challenges';
const getChallengePath = (challengeId) => getEntityPath(exports.challengesPath, challengeId);
exports.getChallengePath = getChallengePath;
exports.settingsPath = 'settings';
const getSettingPath = (settingId) => getEntityPath(exports.settingsPath, settingId);
exports.getSettingPath = getSettingPath;
exports.challengeAttemptsPath = 'challengeAttempts';
const getChallengeAttemptPath = (challengeAttemptId) => getEntityPath(exports.challengeAttemptsPath, challengeAttemptId);
exports.getChallengeAttemptPath = getChallengeAttemptPath;
exports.ratingsPath = 'ratings';
const getRatingPath = (entityCollectionPath, entityId, userId) => getEntityPath(exports.ratingsPath, getUniqueUserEntityId(entityCollectionPath, entityId, userId));
exports.getRatingPath = getRatingPath;
exports.issueReportsPath = 'issueReports';
const getIssueReportPath = (entityCollectionPath, entityId, userId) => getEntityPath(exports.issueReportsPath, getUniqueUserEntityId(entityCollectionPath, entityId, userId));
exports.getIssueReportPath = getIssueReportPath;
// requires subcollection to easily iterate through network impacts
// in order to create recursive network impacts
exports.impactedUsersPath = 'impactedUsers';
exports.networkImpactsPath = 'networkImpacts';
const getImpactedUserNetworkImpactsPath = (userId) => `${exports.impactedUsersPath}/${userId}/${exports.networkImpactsPath}`;
exports.getImpactedUserNetworkImpactsPath = getImpactedUserNetworkImpactsPath;
const getNetworkImpactPath = (toUserId, fromUserId) => `${exports.impactedUsersPath}/${toUserId}/${exports.networkImpactsPath}/${fromUserId}`;
exports.getNetworkImpactPath = getNetworkImpactPath;
const getConversionPath = (affiliateId, convertedUserId) => `${(0, exports.getAffiliatePath)(affiliateId)}/conversions/${convertedUserId}`;
exports.getConversionPath = getConversionPath;
exports.purchasesPath = 'purchases';
const getPurchasePath = (entityCollectionPath, entityId, userId) => getEntityPath(exports.purchasesPath, getUniqueUserEntityId(entityCollectionPath, entityId, userId));
exports.getPurchasePath = getPurchasePath;
exports.donationsPath = 'donations';
exports.paymentsPath = 'payments';
const getPaymentPath = (paymentHash) => getEntityPath(exports.paymentsPath, paymentHash);
exports.getPaymentPath = getPaymentPath;
exports.loginCodesPath = 'loginCodes';
const getLoginCodePath = (email) => getEntityPath(exports.loginCodesPath, email);
exports.getLoginCodePath = getLoginCodePath;
function getEntityPath(entityCollectionPath, entityId) {
    return `${entityCollectionPath}/${entityId}`;
}
exports.getEntityPath = getEntityPath;
// ratings and purchases have IDs based on the user who created them
// and the entity they are for to be easily retrievable
function getUniqueUserEntityId(entityCollectionPath, entityId, userId) {
    return `${entityCollectionPath}-${entityId}-${userId}`;
}
exports.getUniqueUserEntityId = getUniqueUserEntityId;
