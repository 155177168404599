import { FormattedMessage } from 'react-intl';
import FlexBox from '../FlexBox';
import LoadingSpinner from '../LoadingSpinner';
import Typography from '@mui/material/Typography';
import { CodeForm } from '@/components/ui/Login/CodeForm';
import { EmailForm } from '@/components/ui/Login/EmailForm';
import useLoginStore from '@/state/LoginStore';
import shallow from 'zustand/shallow';
import { PaymentStep } from '@/components/ui/Login/PaymentStep';
import React from 'react';
import { borderRadiuses, zIndexes } from '@/theme/theme';

export interface LoginProps {
  onLogin?(userId: string): void;
  onClose?(): void;
}

export default function Login({ onLogin, onClose }: LoginProps) {
  const [isLoading, invoice, codeSent] = useLoginStore(
    (store) => [store.isLoading, store.invoice, store.codeSent],
    shallow
  );

  const handleSuccess = React.useCallback(
    (userId: string) => {
      onLogin?.(userId);
      onClose?.();
    },
    [onLogin, onClose]
  );

  return (
    <FlexBox flex={1} zIndex={zIndexes.above} p={1}>
      <FlexBox
        px={4}
        py={1}
        bgcolor="background.paper"
        borderRadius={borderRadiuses.base}
      >
        <Typography variant="h5" align="center">
          {codeSent ? (
            <FormattedMessage
              defaultMessage="Email Verification"
              description="Login page Email Verification"
            />
          ) : invoice ? (
            <FormattedMessage
              defaultMessage="Payment"
              description="Login page pay invoice"
            />
          ) : null}
        </Typography>

        {isLoading ? (
          <FlexBox flex={1} py={4}>
            <LoadingSpinner />
          </FlexBox>
        ) : codeSent ? (
          <CodeForm onSuccess={handleSuccess} />
        ) : invoice ? (
          <PaymentStep />
        ) : (
          <EmailForm />
        )}
      </FlexBox>
    </FlexBox>
  );
}
