"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var CellType;
(function (CellType) {
    CellType["Normal"] = "Normal";
    CellType["Laser"] = "Laser";
    CellType["Deadly"] = "Deadly";
    CellType["Key"] = "Key";
    CellType["Lock"] = "Lock";
    CellType["FinishChallenge"] = "FinishChallenge";
    CellType["Wafer"] = "Wafer";
    CellType["Infection"] = "Infection";
    CellType["RockGenerator"] = "RockGenerator";
    CellType["Rock"] = "Rock";
    CellType["SpawnLocation"] = "SpawnLocation";
    CellType["Gesture"] = "Gesture";
    CellType["ReverseLock"] = "ReverseLock";
    CellType["Checkpoint"] = "Checkpoint";
    CellType["Switch"] = "Switch";
    CellType["BridgeCreator"] = "BridgeCreator";
    CellType["PartialClear"] = "PartialClear";
})(CellType || (CellType = {}));
exports.default = CellType;
