import {
  SimulationSettings,
  WorldType,
  WorldVariation,
} from 'infinitris2-models';
import create from 'zustand';

export type SinglePlayerOptionsFormData = {
  gridNumRows: number;
  gridNumColumns: number;
  spectate: boolean;
  isDemo: boolean;
  worldType: WorldType;
  worldVariation: WorldVariation;
  simulationSettings: SimulationSettings;
};

export const getSinglePlayerOptionsDefaultValues =
  (): SinglePlayerOptionsFormData => ({
    gridNumRows: 16,
    gridNumColumns: 50,
    spectate: false,
    isDemo: false,

    worldType: 'grass',
    worldVariation: '0',
    simulationSettings: {
      roundLength: 'medium',
      mistakeDetection: true,
      calculateSpawnDelays: true,
      preventTowers: true,
      instantDrops: true,
      gameModeType: 'infinity',
      botSettings: {
        numBots: 4,
        botReactionDelay: 15,
        botRandomReactionDelay: 25,
      },
    },
  });

type SinglePlayerOptionsStore = {
  formData: SinglePlayerOptionsFormData;
  setFormData(formData: SinglePlayerOptionsFormData): void;
  reset(): void;
};

const useSinglePlayerOptionsStore = create<SinglePlayerOptionsStore>((set) => ({
  formData: getSinglePlayerOptionsDefaultValues(),
  setFormData: (formData: SinglePlayerOptionsFormData) =>
    set((_) => ({ formData })),
  reset: () =>
    set((_) => ({ formData: getSinglePlayerOptionsDefaultValues() })),
}));

export default useSinglePlayerOptionsStore;
