var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getFirestore, getDoc, doc, onSnapshot, } from 'firebase/firestore';
import React from 'react';
import useSWR, { useSWRConfig } from 'swr';
import { log } from '../utils/log';
function useDocumentInternal(path) {
    const { cache, compare } = useSWRConfig();
    const fetcher = React.useCallback(() => __awaiter(this, void 0, void 0, function* () {
        if (!path) {
            throw new Error('path is null');
        }
        log('fetch document start', path);
        let result = yield getDoc(doc(getFirestore(), path));
        const cachedValue = cache.get(path);
        const isSame = compare(result.data(), cachedValue === null || cachedValue === void 0 ? void 0 : cachedValue.data());
        log('fetch document end', path, isSame);
        if (isSame) {
            // return cached object to not trigger a re-render
            result = cachedValue;
        }
        return result;
    }), [path, cache, compare]);
    return useSWR(path, fetcher);
}
export function useDocument(path, options) {
    const { cache, compare } = useSWRConfig();
    const swr = useDocumentInternal(path);
    const swrMutate = swr.mutate;
    React.useEffect(() => {
        let unsub = () => { };
        if (path && (options === null || options === void 0 ? void 0 : options.listen)) {
            unsub = onSnapshot(doc(getFirestore(), path), (doc) => {
                const cachedValue = cache.get(path);
                const isSame = compare(doc.data(), cachedValue === null || cachedValue === void 0 ? void 0 : cachedValue.data());
                log('document onSnapshot', path, isSame);
                if (!isSame) {
                    // only update the document if the data actually changed
                    swrMutate(doc, false);
                }
            });
        }
        return unsub;
    }, [options === null || options === void 0 ? void 0 : options.listen, path, cache, compare, swrMutate]);
    return swr;
}
