"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCompositeColors = exports.getBorderColor = exports.adjustColor = void 0;
const hexToRgb_1 = require("./hexToRgb");
const rgbToHex_1 = require("./rgbToHex");
const rotateColor_1 = require("./rotateColor");
//https://stackoverflow.com/a/57401891/4562693
function adjustColor(color, amount) {
    return ('#' +
        color
            .replace(/^#/, '')
            .replace(/../g, (color) => ('0' +
            Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2)));
}
exports.adjustColor = adjustColor;
function getBorderColor(color) {
    return adjustColor(color, -20);
}
exports.getBorderColor = getBorderColor;
function getCompositeColors(color) {
    let { r, g, b } = (0, hexToRgb_1.hexToRgb)(color);
    // isShade approximation https://stackoverflow.com/a/34622484/4562693
    const brightness = Math.min(r, g, b);
    const darkness = Math.max(r, g, b);
    const colorfulness = darkness - brightness;
    const isShade = colorfulness < 20;
    let habitatBaseColor = color;
    //console.log(index, color, colorfulness, isShade, darkness, brightness);
    if (!isShade && colorfulness < 150) {
        const decreaseComponent = (c) => Math.max(c - (150 - colorfulness) * 4, 0);
        const increaseComponent = (c) => Math.min(c + Math.floor((150 - colorfulness) * 2), 230);
        // make habitat more colorful
        for (let i = 0; i < 2; i++) {
            if (r > g && r > b) {
                habitatBaseColor = (0, rgbToHex_1.rgbToHex)(increaseComponent(r), decreaseComponent(g), decreaseComponent(b));
            }
            else if (g > r && g > b) {
                habitatBaseColor = (0, rgbToHex_1.rgbToHex)(decreaseComponent(r), increaseComponent(g), decreaseComponent(b));
            }
            else {
                habitatBaseColor = (0, rgbToHex_1.rgbToHex)(decreaseComponent(r), decreaseComponent(g), increaseComponent(b));
            }
        }
        //console.log('Colorfied');
    }
    if (brightness > 200) {
        habitatBaseColor = adjustColor(habitatBaseColor, -30);
        //console.log('Darkened');
    }
    else if (brightness < 50) {
        habitatBaseColor = adjustColor(habitatBaseColor, 50);
        //console.log('Brightened');
    }
    const comp1 = isShade
        ? adjustColor(habitatBaseColor, -30)
        : (0, rotateColor_1.rotateColor)(habitatBaseColor, 160);
    const comp1b = isShade
        ? adjustColor(habitatBaseColor, -50)
        : (0, rotateColor_1.rotateColor)(habitatBaseColor, 130);
    const comp2 = isShade
        ? adjustColor(habitatBaseColor, 30)
        : (0, rotateColor_1.rotateColor)(habitatBaseColor, 200);
    const comp2b = isShade
        ? adjustColor(habitatBaseColor, 50)
        : (0, rotateColor_1.rotateColor)(habitatBaseColor, 230);
    return { comp1, comp1b, comp2, comp2b };
}
exports.getCompositeColors = getCompositeColors;
